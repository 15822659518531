import React, { Component } from "react";

let TeamContent = [
    {
        images: '01',
        title: 'Luszol ~ Resihub Team'
    }
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Luszol Team"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                            </div>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;